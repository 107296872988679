import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";
import faq from "../../json/faq.json";
import Accordion from "../../components/layout/Accordion";
import AppDownload from "../../components/layout/AppDownload";

const ResidentialWaste = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Residential Waste Pickup"
                subtitle="Your Partner in Cleanliness and Sustainability"
                description="Welcome to Ebusaka's Residential Waste Pickup service – your trusted partner in keeping your home clean, promoting sustainability, and contributing to a greener future for Zambia. We are more than just a waste collection service; we are your ally in creating a cleaner and healthier environment for generations to come."
                img_src={"/assets/resources/residential-waste.jpeg"}
            />
            <section id="residential-waste">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1>Why Choose Ebusaka's Residential Waste Pickup</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_299_2239)">
                                        <path d="M1.95703 24.002C1.95703 26.8966 2.52716 29.7628 3.63488 32.4371C4.74259 35.1113 6.36619 37.5412 8.41298 39.588C10.4598 41.6348 12.8897 43.2584 15.5639 44.3661C18.2382 45.4738 21.1044 46.044 23.999 46.044C26.8936 46.044 29.7599 45.4738 32.4341 44.3661C35.1084 43.2584 37.5383 41.6348 39.5851 39.588C41.6319 37.5412 43.2555 35.1113 44.3632 32.4371C45.4709 29.7628 46.041 26.8966 46.041 24.002C46.041 18.1561 43.7188 12.5496 39.5851 8.41591C35.4514 4.28224 29.8449 1.95996 23.999 1.95996C18.1531 1.95996 12.5467 4.28224 8.41298 8.41591C4.27931 12.5496 1.95703 18.1561 1.95703 24.002Z" stroke="#359636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M35.5013 35.502L26.0273 26.03" stroke="#359636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M21.126 24.0019C21.1159 24.3858 21.1827 24.7679 21.3227 25.1256C21.4626 25.4832 21.6728 25.8092 21.9407 26.0844C22.2087 26.3595 22.5291 26.5782 22.8829 26.7275C23.2368 26.8768 23.6169 26.9537 24.001 26.9537C24.3851 26.9537 24.7652 26.8768 25.1191 26.7275C25.4729 26.5782 25.7933 26.3595 26.0613 26.0844C26.3292 25.8092 26.5394 25.4832 26.6793 25.1256C26.8193 24.7679 26.8861 24.3858 26.876 24.0019C26.8861 23.618 26.8193 23.2359 26.6793 22.8782C26.5394 22.5205 26.3292 22.1945 26.0613 21.9194C25.7933 21.6443 25.4729 21.4256 25.1191 21.2763C24.7652 21.127 24.3851 21.05 24.001 21.05C23.6169 21.05 23.2368 21.127 22.8829 21.2763C22.5291 21.4256 22.2087 21.6443 21.9407 21.9194C21.6728 22.1945 21.4626 22.5205 21.3227 22.8782C21.1827 23.2359 21.1159 23.618 21.126 24.0019Z" stroke="#359636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M24 13.46V21.126" stroke="#359636" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_299_2239">
                                            <rect width="48" height="48" fill="white"/>
                                        </clipPath>
                                    </defs>
                                </svg>
                                <h2>Convenience and Flexibility</h2>
                                <p>With our monthly subscription, you can enjoy hassle-free weekly waste collections right at your doorstep. No more worrying about missed pickups or overflowing bins – we've got you covered!</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.043 34.5439C23.043 36.0541 23.3404 37.5496 23.9184 38.9448C24.4963 40.34 25.3434 41.6078 26.4112 42.6757C27.4791 43.7435 28.7469 44.5906 30.1421 45.1686C31.5374 45.7465 33.0328 46.0439 34.543 46.0439C36.0532 46.0439 37.5486 45.7465 38.9438 45.1686C40.3391 44.5906 41.6068 43.7435 42.6747 42.6757C43.7426 41.6078 44.5897 40.34 45.1676 38.9448C45.7455 37.5496 46.043 36.0541 46.043 34.5439C46.043 33.0337 45.7455 31.5383 45.1676 30.1431C44.5897 28.7478 43.7426 27.4801 42.6747 26.4122C41.6068 25.3443 40.3391 24.4973 38.9438 23.9193C37.5486 23.3414 36.0532 23.0439 34.543 23.0439C33.0328 23.0439 31.5374 23.3414 30.1421 23.9193C28.7469 24.4973 27.4791 25.3443 26.4112 26.4122C25.3434 27.4801 24.4963 28.7478 23.9184 30.1431C23.3404 31.5383 23.043 33.0337 23.043 34.5439Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M38.377 34.5439H34.543V28.7939" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.207 36.458H3.87303C3.36488 36.458 2.87753 36.2561 2.51821 35.8968C2.15889 35.5375 1.95703 35.0501 1.95703 34.542V7.70799C1.95703 7.19984 2.15889 6.7125 2.51821 6.35318C2.87753 5.99386 3.36488 5.79199 3.87303 5.79199H38.373C38.8812 5.79199 39.3685 5.99386 39.7278 6.35318C40.0872 6.7125 40.289 7.19984 40.289 7.70799V20.166" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M11.543 1.95996V11.544" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30.707 1.95996V11.544" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.95703 15.376H40.291" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Special Occasion Pickup</h2>
                                <p>Planning a party or event? Don't stress about the post-celebration cleanup. Our once-off special pickup option allows you to request waste collection when you need it most.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.8635 3.80204C22.9576 3.56539 23.1206 3.36243 23.3313 3.21944C23.5421 3.07644 23.7909 3 24.0455 3C24.3002 3 24.549 3.07644 24.7598 3.21944C24.9705 3.36243 25.1335 3.56539 25.2275 3.80204L26.5915 7.21204H29.4775C29.7384 7.21173 29.993 7.29162 30.2069 7.44089C30.4209 7.59016 30.5837 7.80159 30.6734 8.04653C30.7632 8.29146 30.7754 8.55805 30.7085 8.81018C30.6417 9.06231 30.4989 9.28779 30.2995 9.45604L27.8635 11.514L28.7475 14.716C28.8198 14.9745 28.8086 15.2491 28.7153 15.5008C28.6221 15.7524 28.4516 15.9681 28.2284 16.117C28.0052 16.2659 27.7405 16.3404 27.4724 16.3298C27.2043 16.3192 26.9464 16.2241 26.7355 16.058L24.0415 13.948L21.3575 16.034C21.1465 16.1976 20.8894 16.2907 20.6226 16.3C20.3558 16.3093 20.0928 16.2345 19.8709 16.086C19.649 15.9376 19.4794 15.723 19.3862 15.4729C19.293 15.2227 19.2809 14.9495 19.3515 14.692L20.2315 11.53L17.7835 9.45604C17.5842 9.28779 17.4414 9.06231 17.3746 8.81018C17.3077 8.55805 17.3199 8.29146 17.4096 8.04653C17.4994 7.80159 17.6622 7.59016 17.8761 7.44089C18.0901 7.29162 18.3447 7.21173 18.6055 7.21204H21.4975L22.8635 3.80204Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M7.53151 15.302C7.6256 15.0654 7.78857 14.8624 7.9993 14.7194C8.21004 14.5764 8.45884 14.5 8.71351 14.5C8.96818 14.5 9.21699 14.5764 9.42773 14.7194C9.63846 14.8624 9.80142 15.0654 9.89551 15.302L11.2595 18.712H14.1455C14.4064 18.7117 14.661 18.7916 14.8749 18.9409C15.0888 19.0902 15.2517 19.3016 15.3414 19.5465C15.4311 19.7915 15.4434 20.0581 15.3765 20.3102C15.3096 20.5623 15.1668 20.7878 14.9675 20.956L12.5315 23.014L13.4155 26.216C13.4878 26.4745 13.4765 26.7491 13.3833 27.0008C13.2901 27.2524 13.1196 27.4681 12.8964 27.617C12.6731 27.7659 12.4085 27.8404 12.1404 27.8298C11.8722 27.8192 11.6143 27.7241 11.4035 27.558L8.70951 25.448L6.02551 27.534C5.81452 27.6976 5.55739 27.7907 5.29057 27.8C5.02375 27.8093 4.76076 27.7345 4.53885 27.586C4.31694 27.4376 4.14738 27.223 4.05417 26.9729C3.96096 26.7227 3.94883 26.4495 4.01951 26.192L4.89951 23.03L2.45151 20.956C2.25218 20.7878 2.1094 20.5623 2.04252 20.3102C1.97564 20.0581 1.98789 19.7915 2.07761 19.5465C2.16733 19.3016 2.33019 19.0902 2.54411 18.9409C2.75803 18.7916 3.01266 18.7117 3.27351 18.712H6.16551L7.53151 15.302Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M38.1952 15.302C38.2893 15.0654 38.4523 14.8624 38.663 14.7194C38.8737 14.5764 39.1225 14.5 39.3772 14.5C39.6319 14.5 39.8807 14.5764 40.0914 14.7194C40.3021 14.8624 40.4651 15.0654 40.5592 15.302L41.9232 18.712H44.8092C45.0701 18.7117 45.3247 18.7916 45.5386 18.9409C45.7525 19.0902 45.9154 19.3016 46.0051 19.5465C46.0948 19.7915 46.1071 20.0581 46.0402 20.3102C45.9733 20.5623 45.8305 20.7878 45.6312 20.956L43.1952 23.014L44.0792 26.216C44.1515 26.4745 44.1402 26.7491 44.047 27.0008C43.9537 27.2524 43.7833 27.4681 43.5601 27.617C43.3368 27.7659 43.0722 27.8404 42.8041 27.8298C42.5359 27.8192 42.278 27.7241 42.0672 27.558L39.3832 25.45L36.6992 27.536C36.4882 27.6996 36.2311 27.7927 35.9643 27.802C35.6974 27.8113 35.4344 27.7365 35.2125 27.588C34.9906 27.4396 34.8211 27.225 34.7279 26.9749C34.6346 26.7247 34.6225 26.4515 34.6932 26.194L35.5732 23.032L33.1312 20.964C32.9319 20.7958 32.7891 20.5703 32.7222 20.3182C32.6553 20.0661 32.6676 19.7995 32.7573 19.5545C32.847 19.3096 33.0099 19.0982 33.2238 18.9489C33.4377 18.7996 33.6924 18.7197 33.9532 18.72H36.8452L38.1952 15.302Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.4452 32.552C32.5393 32.3154 32.7023 32.1124 32.913 31.9694C33.1237 31.8264 33.3725 31.75 33.6272 31.75C33.8819 31.75 34.1307 31.8264 34.3414 31.9694C34.5521 32.1124 34.7151 32.3154 34.8092 32.552L36.1732 35.962H39.0592C39.3201 35.9617 39.5747 36.0416 39.7886 36.1909C40.0025 36.3402 40.1654 36.5516 40.2551 36.7965C40.3448 37.0415 40.3571 37.3081 40.2902 37.5602C40.2233 37.8123 40.0805 38.0378 39.8812 38.206L37.4452 40.264L38.3292 43.466C38.4015 43.7245 38.3902 43.9991 38.297 44.2508C38.2037 44.5024 38.0333 44.7181 37.8101 44.867C37.5868 45.0159 37.3222 45.0904 37.0541 45.0798C36.7859 45.0692 36.528 44.9741 36.3172 44.808L33.6332 42.7L30.9492 44.786C30.7382 44.9496 30.4811 45.0427 30.2143 45.052C29.9474 45.0613 29.6844 44.9865 29.4625 44.838C29.2406 44.6896 29.0711 44.475 28.9779 44.2249C28.8846 43.9747 28.8725 43.7015 28.9432 43.444L29.8232 40.282L27.3812 38.214C27.1819 38.0458 27.0391 37.8203 26.9722 37.5682C26.9053 37.3161 26.9176 37.0495 27.0073 36.8045C27.097 36.5596 27.2599 36.3482 27.4738 36.1989C27.6877 36.0496 27.9424 35.9697 28.2032 35.97H31.0952L32.4452 32.552Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M13.2815 32.552C13.3756 32.3154 13.5386 32.1124 13.7493 31.9694C13.96 31.8264 14.2088 31.75 14.4635 31.75C14.7182 31.75 14.967 31.8264 15.1777 31.9694C15.3885 32.1124 15.5514 32.3154 15.6455 32.552L17.0095 35.962H19.8955C20.1564 35.9617 20.411 36.0416 20.6249 36.1909C20.8388 36.3402 21.0017 36.5516 21.0914 36.7965C21.1811 37.0415 21.1934 37.3081 21.1265 37.5602C21.0596 37.8123 20.9168 38.0378 20.7175 38.206L18.2815 40.264L19.1655 43.466C19.2378 43.7245 19.2265 43.9991 19.1333 44.2508C19.0401 44.5024 18.8696 44.7181 18.6464 44.867C18.4231 45.0159 18.1585 45.0904 17.8904 45.0798C17.6222 45.0692 17.3643 44.9741 17.1535 44.808L14.4595 42.698L11.7755 44.784C11.5645 44.9476 11.3074 45.0407 11.0406 45.05C10.7737 45.0593 10.5108 44.9845 10.2889 44.836C10.0669 44.6876 9.89738 44.473 9.80417 44.2229C9.71096 43.9727 9.69883 43.6995 9.76951 43.442L10.6495 40.28L8.20151 38.206C8.00219 38.0378 7.8594 37.8123 7.79252 37.5602C7.72564 37.3081 7.73789 37.0415 7.82761 36.7965C7.91733 36.5516 8.08019 36.3402 8.29411 36.1909C8.50803 36.0416 8.76266 35.9617 9.02351 35.962H11.9155L13.2815 32.552Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Rating & Feedback System</h2>
                                <p>Your satisfaction is our priority. Rate your garbage collectors after each pickup and provide feedback on your experience. Your valuable input helps us continuously improve our service delivery.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-4">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.83397 46.042C4.32581 46.042 3.83847 45.8401 3.47915 45.4808C3.11983 45.1215 2.91797 44.6341 2.91797 44.126" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.1641 46.042C20.4157 46.042 20.6648 45.9924 20.8973 45.8961C21.1297 45.7998 21.341 45.6587 21.5189 45.4808C21.6968 45.3029 21.8379 45.0917 21.9342 44.8592C22.0305 44.6267 22.0801 44.3776 22.0801 44.126" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.91406 40.292V38.376" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.584 46.042H8.66797" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.584 26.876H8.66797" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.334 46.042H14.418" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.334 26.876H14.418" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M20.1641 26.876C20.4157 26.876 20.6648 26.9255 20.8973 27.0218C21.1297 27.1181 21.341 27.2592 21.5189 27.4372C21.6968 27.6151 21.8379 27.8263 21.9342 28.0588C22.0305 28.2912 22.0801 28.5404 22.0801 28.792" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.0859 32.626V34.542" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.83397 26.876C4.32581 26.876 3.83847 27.0778 3.47915 27.4372C3.11983 27.7965 2.91797 28.2838 2.91797 28.792" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2.91406 32.626V34.542" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.0859 38.376V40.292" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.83397 1.95801H20.168C20.168 1.95801 22.084 1.95801 22.084 3.87401V19.208C22.084 19.208 22.084 21.124 20.168 21.124H4.83397C4.83397 21.124 2.91797 21.124 2.91797 19.208V3.87401C2.91797 3.87401 2.91797 1.95801 4.83397 1.95801Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M45.0859 29.718L37.4199 37.384L29.7539 29.718" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M37.4141 7.70801V37.384" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Incentives for Waste Sorting</h2>
                                <p>We encourage responsible waste sorting at home. As a thank-you, we offer incentives for sorting recyclable materials, making your efforts in waste management even more rewarding.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-4">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M46.041 24C46.041 24 36.175 36.458 23.999 36.458C11.823 36.458 1.95703 24 1.95703 24C1.95703 24 11.825 11.542 23.999 11.542C36.173 11.542 46.041 24 46.041 24Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M16.3359 24C16.3359 25.0069 16.5343 26.0038 16.9196 26.934C17.3049 27.8642 17.8696 28.7094 18.5816 29.4214C19.2935 30.1333 20.1387 30.6981 21.0689 31.0834C21.9991 31.4687 22.9961 31.667 24.0029 31.667C25.0098 31.667 26.0068 31.4687 26.937 31.0834C27.8672 30.6981 28.7124 30.1333 29.4243 29.4214C30.1363 28.7094 30.701 27.8642 31.0863 26.934C31.4716 26.0038 31.6699 25.0069 31.6699 24C31.6699 22.9932 31.4716 21.9962 31.0863 21.066C30.701 20.1358 30.1363 19.2906 29.4243 18.5786C28.7124 17.8667 27.8672 17.3019 26.937 16.9166C26.0068 16.5313 25.0098 16.333 24.0029 16.333C22.9961 16.333 21.9991 16.5313 21.0689 16.9166C20.1387 17.3019 19.2935 17.8667 18.5816 18.5786C17.8696 19.2906 17.3049 20.1358 16.9196 21.066C16.5343 21.9962 16.3359 22.9932 16.3359 24Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.9981 20.166C24.7564 20.166 25.4976 20.3909 26.1281 20.8122C26.7586 21.2334 27.25 21.8322 27.5402 22.5328C27.8304 23.2334 27.9063 24.0043 27.7584 24.748C27.6105 25.4917 27.2453 26.1749 26.7091 26.7111C26.1729 27.2473 25.4898 27.6124 24.746 27.7603C24.0023 27.9083 23.2314 27.8324 22.5309 27.5422C21.8303 27.252 21.2315 26.7606 20.8102 26.1301C20.3889 25.4996 20.1641 24.7583 20.1641 24" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Transparency & Accountability</h2>
                                <p>With Ebusaka, you are always in the know. Our real-time tracking feature enables you to monitor the status of your pickups, ensuring transparency and accountability.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-4">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.707 39.0439V46.0419" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30.707 39.0439L32.767 38.3579C33.5968 38.0592 34.4853 37.96 35.3605 38.0684C36.2358 38.1768 37.0732 38.4897 37.805 38.9819C38.7334 39.51 39.7575 39.8482 40.8179 39.9766C41.8782 40.105 42.9534 40.0212 43.981 39.7299L46.041 39.0439V29.1939C46.0413 29.0419 46.0053 28.892 45.9362 28.7566C45.867 28.6212 45.7667 28.5042 45.6433 28.4153C45.52 28.3264 45.3773 28.2681 45.2271 28.2453C45.0768 28.2224 44.9232 28.2356 44.779 28.2839L43.979 28.5499C41.8809 29.1106 39.6468 28.8315 37.751 27.7719C37.0155 27.3114 36.1872 27.0193 35.3256 26.9166C34.4639 26.8139 33.5902 26.9031 32.767 27.1779L30.707 27.8639V39.0439Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.95703 30.708C1.95703 27.658 3.16863 24.7329 5.3253 22.5763C7.48197 20.4196 10.407 19.208 13.457 19.208C16.507 19.208 19.4321 20.4196 21.5888 22.5763C23.7454 24.7329 24.957 27.658 24.957 30.708H1.95703Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.26953 9.14601C6.26953 10.0899 6.45545 11.0246 6.81669 11.8967C7.17792 12.7688 7.70738 13.5612 8.37485 14.2287C9.04232 14.8962 9.83471 15.4256 10.7068 15.7869C11.5789 16.1481 12.5136 16.334 13.4575 16.334C14.4015 16.334 15.3362 16.1481 16.2083 15.7869C17.0803 15.4256 17.8727 14.8962 18.5402 14.2287C19.2077 13.5612 19.7371 12.7688 20.0984 11.8967C20.4596 11.0246 20.6455 10.0899 20.6455 9.14601C20.6455 7.23963 19.8882 5.41134 18.5402 4.06332C17.1922 2.71531 15.3639 1.95801 13.4575 1.95801C11.5512 1.95801 9.72286 2.71531 8.37485 4.06332C7.02684 5.41134 6.26953 7.23963 6.26953 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.4805 9.14601C24.4805 11.0524 25.2378 12.8807 26.5858 14.2287C27.9338 15.5767 29.7621 16.334 31.6685 16.334C33.5748 16.334 35.4031 15.5767 36.7512 14.2287C38.0992 12.8807 38.8565 11.0524 38.8565 9.14601C38.8565 7.23963 38.0992 5.41134 36.7512 4.06332C35.4031 2.71531 33.5748 1.95801 31.6685 1.95801C29.7621 1.95801 27.9338 2.71531 26.5858 4.06332C25.2378 5.41134 24.4805 7.23963 24.4805 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.2441 23.0421C38.4837 21.0702 36.0977 19.7649 33.4877 19.346C30.8777 18.9271 28.2031 19.4201 25.9141 20.7421" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Empowering the Community</h2>
                                <p>By choosing Ebusaka, you are contributing to empowering women and youths in low-income communities through our "Trade Trash for Cash" initiative. Their efforts in collecting recyclable materials directly benefit from your contributions.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section id="residential-waste-payment">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5 order-1 order-md-0 mt-4 mt-md-0">
                            <img src="/assets/resources/payment-scrn.png" alt="payment screen" className="img-fluid"/>
                        </div>

                        <div className="col-12 col-md-7 my-auto order-0 order-md-1">
                            <h1>Seamless Payments and Security</h1>
                            <p>Your safety and convenience matter to us. All payments for Residential Waste Pickup are made through secure mobile money, VISA, and Mastercard transactions. Our user-friendly payment system ensures a smooth and hassle-free experience for you.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section id="gl-content">
                <div className="container">
                    <div className="row" style={{marginBottom: 68}}>
                        <div className="col-12">
                            <h1>Be Part of the Solution</h1>
                            <p>At Ebusaka, we believe that collective efforts make a significant impact. By subscribing to our Residential Waste Pickup service, you become an active participant in the movement to keep our country clean and sustainable.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h1>Embrace the Ebusaka Difference:</h1>
                            <p>Join us in creating a cleaner and greener lifestyle. Let Ebusaka be your partner in waste management, environmental preservation, and sustainability. Together, we can build a circular economy that benefits everyone.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Accordion
                title="Frequently Asked Questions (FAQs)"
                data={faq.support}
            />

            <AppDownload
                img_src="/assets/resources/app-download.png"
                title="Take Action Today"
                description={
                    [
                        "Subscribe to Ebusaka's Residential Waste Pickup service and become a changemaker in waste management. By making conscious choices today, you are shaping a brighter, greener, and more sustainable tomorrow for Zambia.",
                        "Together, let's make a positive impact on our environment, one pickup at a time. Ebusaka – Your Partner in Cleanliness and Sustainability."
                    ]
                }
            />

            <Footer/>
        </>
    );
};

export default ResidentialWaste;
