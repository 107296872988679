import React, {useState} from 'react';
import {subscribe} from "../../api/app";
import $ from "jquery";

const Subscribe = () => {

    const [loading, setLoading] = useState(false);

    const newsLetter = (e) => {
        e.preventDefault();
        setLoading(true);
        let data = {
            name: $("#inputName").val(),
            email: $("#inputEmail").val()
        }
        subscribe(data).then(() => {
            setLoading(false);
            $(':input',"#subscribe-form")
                .not(':button, :submit, :reset, :hidden')
                .val('')
                .prop('checked', false)
                .prop('selected', false);
            $("#modalMessage").modal({ backdrop: 'static', focus: false, show: true });
        });
    }

    return (
        <section id="subscriber">
            <div className="container">
                <h1>Subscribe to Newsletter</h1>
                <p>Stay informed and be ahead of the curve with our carefully curated content delivered straight to your inbox.</p>

                <form id="subscribe-form" onSubmit={newsLetter.bind()}>
                    <div className="form-row">
                        <div className="col-12 col-md-4 offset-md-1">
                            <label htmlFor="inputName">Full Name</label>
                            <input type="text" className="form-control" id="inputName" placeholder="Enter your full name" required/>
                        </div>
                        <div className="col-12 col-md-4 mt-4 mt-md-0">
                            <label htmlFor="inputEmail">Email Address</label>
                            <input type="email" className="form-control" id="inputEmail" placeholder="Enter your email address" required/>
                        </div>
                        <div className="col-12 col-md-2 mt-4 mt-md-0 d-flex align-content-end flex-wrap">
                            {!loading ? <button className="btn btn-primary btn-block">Subscribe</button> : <button className="btn btn-secondary" disabled>Please Wait</button>}
                        </div>
                    </div>
                </form>

                <div className="modal" id="modalMessage" tabIndex="-1" role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                                <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                    </svg>
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className="text-center">
                                    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.625 50C15.625 31.0152 31.0152 15.625 50 15.625C68.9848 15.625 84.375 31.0152 84.375 50C84.375 68.9848 68.9848 84.375 50 84.375C31.0152 84.375 15.625 68.9848 15.625 50ZM50 9.375C27.5634 9.375 9.375 27.5634 9.375 50C9.375 72.4366 27.5634 90.625 50 90.625C72.4366 90.625 90.625 72.4366 90.625 50C90.625 27.5634 72.4366 9.375 50 9.375ZM68.8764 39.7097C70.0968 38.4893 70.0968 36.5107 68.8764 35.2903C67.656 34.0699 65.6773 34.0699 64.457 35.2903L45.8333 53.9139L39.7097 47.7903C38.4893 46.5699 36.5107 46.5699 35.2903 47.7903C34.0699 49.0107 34.0699 50.9893 35.2903 52.2097L43.6236 60.543C44.2097 61.1291 45.0045 61.4583 45.8333 61.4583C46.6621 61.4583 47.457 61.1291 48.043 60.543L68.8764 39.7097Z" fill="#359636"/>
                                    </svg>
                                    <h2>Thank you for joining our newsletter</h2>
                                    <p>We are thrilled to welcome you to our exclusive newsletter community! Thank you for subscribing and showing your interest in staying connected with us. Our newsletter is designed to provide you with valuable insights, exciting updates, and relevant content straight to your inbox.</p>
                                </div>
                                <div className="mt-4">
                                    <a href="#" className="d-flex justify-content-center" role="button" data-dismiss="modal" aria-label="Close">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                        </svg> Close
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Subscribe;
