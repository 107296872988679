import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SubHeader from "../../components/layout/SubHeader";

const Partner = () => {
    return (
        <>
            <Header/>
            <SubHeader
                title="Our Trusted Partner"
                description="At Ebusaka, collaboration is at the heart of our journey towards transforming waste management in Africa. We believe that working hand-in-hand with stakeholders and partners is essential to achieve our mission of creating a cleaner and healthier environment. Our dedication to making a positive impact has led us to forge meaningful partnerships with various entities."
            />
            <section id="_partner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="_partner-img-contain">
                                <img src="/assets/resources/govt-complex.jpeg" className="img-fluid" alt="government-complex"/>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 my-auto">
                            <h1>Working with Local Government and Municipal Councils</h1>
                            <p>We understand that effective waste management requires collective efforts, and that's why we collaborate closely with local government authorities and municipal councils. By aligning our goals and strategies, we aim to create a seamless waste management ecosystem that benefits communities and safeguards the environment.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 35}}>
                        <div className="col-12 col-md-4">
                            <div className="_partner-img-contain">
                                <img src="/assets/resources/garbage-truck.jpeg" className="img-fluid" alt="garbage-truck"/>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 my-auto">
                            <h1>Reliable and Licensed<br/>Garbage Collectors</h1>
                            <p>Our commitment to excellence drives us to work only with licensed and reliable garbage collectors. These dedicated individuals play a vital role in ensuring the responsible collection and disposal of waste, contributing significantly to our sustainable waste management approach.</p>
                        </div>
                    </div>

                    <div className="row" style={{marginTop: 35}}>
                        <div className="col-12 col-md-4">
                            <div className="_partner-img-contain">
                                <img src="/assets/resources/handshake.jpeg" className="img-fluid" alt="government-complex"/>
                            </div>
                        </div>

                        <div className="col-12 col-md-8 my-auto">
                            <h1>Empowering<br/>Stakeholders</h1>
                            <p>We firmly believe that empowering communities and entrepreneurs is key to achieving long-term sustainability. Through our collaborations with various partners, we support local initiatives, providing resources, training, and opportunities that help waste collectors and recycling communities thrive.</p>
                        </div>
                    </div>

                    <div style={{marginTop: 103}} id="company-logo">
                        <h1>Grateful to Our Supporters</h1>
                        <p>We are incredibly grateful for the unwavering support of our esteemed partners, whose belief in our vision has bolstered our efforts. Together, we strive to make a positive impact on the environment and society. Some of our notable partners include:</p>
                        <div className="row">
                            <div className="col-12 offset-md-1 col-md-10 text-center">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src="/assets/resources/ags-logo.png" className="img-fluid" alt="undp-logo"/>
                                    </div>
                                    <div className="my-auto col-md-10 text-md-left">
                                        <h2>Accelerated Growth for SMEs in Zambia (AGS) Programme</h2>
                                        <p>partnership, we have gained valuable resources and training, accelerating our growth and development as a green tech startup.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 20}}>
                            <div className="col-12 offset-md-1 col-md-10 text-center">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src="/assets/resources/undp-logo.png" className="img-fluid" alt="undp-logo"/>
                                    </div>
                                    <div className="my-auto col-md-10 text-md-left">
                                        <h2>UNDP Accelerator Labs</h2>
                                        <p>Our association with UNDP Accelerator Labs has provided us with a global network of innovators, enhancing our ability to drive sustainable solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 40}}>
                            <div className="col-12 offset-md-1 col-md-10 text-center">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src="/assets/resources/ntbc-logo.jpg" className="img-fluid" alt="undp-logo"/>
                                    </div>
                                    <div className="my-auto col-md-10 text-md-left">
                                        <h2>National Technology Business Centre</h2>
                                        <p>Our association with UNDP Accelerator Labs has provided us with a global network of innovators, enhancing our ability to drive sustainable solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 40}}>
                            <div className="col-12 offset-md-1 col-md-10 text-center">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src="/assets/resources/mtn-logo.jpg" className="img-fluid" alt="undp-logo"/>
                                    </div>
                                    <div className="my-auto col-md-10 text-md-left">
                                        <h2>MTN Zambia</h2>
                                        <p>Through our collaboration with MTN Zambia, we have participated in the prestigious MTN Momo Hackathon, where we showcased our innovative solutions.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row" style={{marginBottom: 40}}>
                            <div className="col-12 offset-md-1 col-md-10 text-center">
                                <div className="row">
                                    <div className="col-md-2">
                                        <img src="/assets/resources/zicta-logo.png" className="img-fluid" alt="undp-logo"/>
                                    </div>
                                    <div className="my-auto col-md-10 text-md-left">
                                        <h2 className="mt-3 mt-md-0">ZICTA</h2>
                                        <p>Our association with ZICTA Innovation Challenge has provided us with a platform to demonstrate our commitment to technological advancement and environmental sustainability.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id="greener-message">
                    <div className="container">
                        <h1>Together Towards a Greener Future</h1>
                        <p>As we move forward on our journey, we remain dedicated to fostering meaningful partnerships that strengthen our collective efforts. With the support of our valued partners, we envision a future where waste is viewed as a valuable resource, contributing to the creation of a cleaner, greener, and more sustainable world.</p>
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default Partner;
