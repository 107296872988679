import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";

const Recycler = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Recyclers"
                subtitle="Unlocking Opportunities in Sustainable Waste Management"
                description="Welcome to Ebusaka's Recyclers page, where we invite recycling businesses to be part of our sustainable waste management ecosystem. As a recycler, you play a vital role in creating a circular economy, conserving resources, and reducing waste pollution. Join us in our mission to transform waste into valuable resources and make a positive impact on the environment and society."
                img_src={"/assets/resources/recycler-bins.jpg"}
            />

            <section id="gl-content" style={{background: "white", marginBottom: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h1>Understanding Plastic Credits</h1>
                            <p>Plastic Credits are a unique concept that enables manufacturing companies to balance their plastic usage by supporting responsible waste management and recycling initiatives. For every kilogram of plastic used in production, businesses can purchase an equivalent amount of Plastic Credits from Ebusaka.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="core" style={{paddingTop: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <img src="/assets/resources/ebusaka-bin.jpg" alt="outgrow" className="img-fluid"/>
                        </div>

                        <div className="col-12 col-md-8 my-auto">
                            <h3 className="mt-3 mt-md-0">Benefits of Joining Ebusaka</h3>
                            <ul className="list-unstyled">
                                <li><span>Streamlined Supply Chain</span><br/> Ebusaka's platform connects recyclers directly with waste generators, streamlining the collection and distribution of recyclable materials.</li>
                                <li><span>Access to Raw Materials</span><br/> Expand your access to a diverse range of high-quality recyclable materials, ensuring a steady supply for your recycling processes.</li>
                                <li><span>Increased Visibility</span><br/> Showcase your recycling facilities and services to a broader audience, boosting your presence in the waste management industry.</li>
                                <li><span>Economic Opportunities</span><br/> Ebusaka's initiatives create new economic opportunities for recyclers, empowering them to thrive and grow their businesses.</li>
                                <li><span>Environmental Impact</span><br/> By recycling more efficiently and effectively, you contribute to significant reductions in greenhouse gas emissions and resource depletion.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section className="impact" style={{background: "white", paddingTop: 20}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Seamless Integration</h1>
                            <p>Joining Ebusaka as a recycler is a seamless and efficient process. Our user-friendly platform allows you to manage your operations, interact with waste generators, and access valuable insights for informed decision-making.</p>
                        </div>

                        <div className="col-md-12" style={{marginTop: 50}}>
                            <h1>Waste Trading Made Easy</h1>
                            <p>Ebusaka's platform facilitates the trading of recyclable materials, enabling waste generators to exchange their waste for incentives or cash rewards. As a recycler, you can easily identify potential trading partners and collaborate to increase recycling rates.</p>
                        </div>

                        <div className="col-md-12" style={{marginTop: 50}}>
                            <h1>Promoting Responsible Recycling</h1>
                            <p>Plastic Credits are a unique concept that enables manufacturing companies to balance their plastic usage by supporting responsible waste management and recycling initiatives. For every kilogram of plastic used in production, businesses can purchase an equivalent amount of Plastic Credits from Ebusaka.</p>
                        </div>

                        <div className="col-md-12" style={{marginTop: 50}}>
                            <h1>Join Our Network</h1>
                            <p>Be part of a community of passionate recyclers committed to making a positive impact. By joining Ebusaka, you position your recycling business at the forefront of sustainable waste management, driving real change in your community and beyond.</p>
                        </div>
                    </div>
                    <img src="/assets/resources/watermark.svg" alt="watermark"/>
                </div>
            </section>

            <section id="_gl-content" style={{marginBottom: 0}}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1>Together We Thrive</h1>
                            <p>At Ebusaka, we believe that collaboration is key to achieving our vision of a waste-free and sustainable future. As a recycler, you are an essential partner in this journey, and your contribution is vital in building a greener, cleaner, and more resilient world.</p>
                        </div>

                        <div className="col-md-12" style={{marginTop: 50}}>
                            <h1>Let's Make a Difference</h1>
                            <p>Contact Ebusaka today to learn more about how you can join our platform as a recycler. Together, let's create a cleaner and healthier environment for current and future generations, one recycled material at a time.</p>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    );
};

export default Recycler;
