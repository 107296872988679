import React from 'react';
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import {Link} from "react-router-dom";
import Assistance from "../components/layout/Assistance";
import SEO from "../components/layout/Seo";

const Home = () => {
    return (
        <>
            <SEO
                title="Ebusaka - Waste Management Platform"
                description="Ebusaka is a mobile and cloud based solution that helps you get dumpster rentals, recycling, trash, and bulk trash pickup services across Zambia."
            />
            <Header/>
            <section id="intro" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1>Embrace the Circular Economy with <span>Ebusaka!</span></h1>
                            <p>Creating a Greener, Cleaner, and More Sustainable Future Together</p>
                            <button className="btn btn-secondary">Explore More Inside Ebusaka</button>
                        </div>
                    </div>
                </div>
            </section>

            <section id="services">
                <div className='container'>
                    <h1>Find Sustainable Waste Management Services</h1>
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-md-5">
                                <label htmlFor="inputAddress">Your Address</label>
                                <input type="text" className="form-control" id="inputAddress" placeholder="Kamwala Street, House #10"/>
                            </div>
                            <div className="col-12 col-md-3 mt-3 mt-md-0">
                                <label for="inlineFormCustomSelectPref">For Home or Business?</label>
                                <select className="custom-select mr-sm-2" id="inlineFormCustomSelectPref">
                                    <option selected>Select</option>
                                    <option value="1">Home</option>
                                    <option value="2">Business</option>
                                </select>
                            </div>
                            <div className="col-12 col-md-4 d-flex align-content-end flex-wrap mt-3 mt-md-0">
                                <Link className="btn btn-primary" to="#">Get Started</Link>
                            </div>
                        </div>
                    </form>
                </div>
            </section>

            <section id="companion">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5 text-center">
                            <img src='/assets/resources/mobile.jpg' className="img-fluid" alt="mobile-companion" />
                        </div>

                        <div className="col-12 col-md-7 my-md-auto mt-5 mt-md-0">
                            <h1>Ebusaka App - Your Waste Management Companion</h1>
                            <p>Say goodbye to waste management woes and hello to hassle-free recycling! The Ebusaka app is your one-stop solution for all waste management needs. Seamlessly connect with garbage collectors, recycling facilities, and sustainable partners. Our user-friendly interface and advanced features make waste management effortless and rewarding.</p>

                            <h2>Key Features</h2>
                            <ul>
                                <li><span>Schedule Collection:</span> Book waste collection services with just a few taps. Choose a convenient time, and our reliable collectors will handle the rest.</li>
                                <li><span>Real-time Tracking:</span> Stay informed about your waste collection status in real-time. Track our collectors' progress and receive timely updates.</li>
                                <li><span>Transparent Pricing:</span> Clear and competitive pricing ensures you get value for your waste and contribute to a greener world.</li>
                                <li><span>Recycling Rewards:</span> Earn eco-friendly rewards for responsibly recycling your waste and promoting a sustainable lifestyle.</li>
                            </ul>

                            <p>Learn more about the Ebusaka app and take the first step towards sustainable waste management today! <Link to="/home/residential-waste-pickup">Learn More</Link></p>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section id="goal">
                <div className="container">
                    <h1>Our Mission, Values, and Impact</h1>
                    <h2>Our Commitment to a Sustainable Tomorrow</h2>

                    <div className="row">
                        <div className="col-12 col-md-4">
                            <img src="/assets/resources/outgrow.jpg" alt="outgrow"/>
                        </div>

                        <div className="col-12 col-md-8 my-auto">
                            <div className="row">
                                <div className="col-12 mb-3 mt-4 mt-md-0">
                                    <h3>Our Missions</h3>
                                    <p>At Ebusaka, our mission is clear - to transform waste management practices through innovative technology and promote a circular economy that nurtures our planet.</p>
                                </div>

                                <div className="col-12 col-md-6">
                                    <h3>Our Core Values</h3>
                                    <hr/>
                                    <ul className="list-unstyled">
                                        <li><span>Integrity</span><br/> Transparency and honesty guide our actions as we build trust with our users and partners.</li>
                                        <li><span>Innovation</span><br/> Embracing cutting-edge technology, we strive to continuously improve waste management practices.</li>
                                        <li><span>Sustainability</span><br/> We are committed to long-term solutions that preserve the environment and reduce our ecological footprint.</li>
                                        <li><span>Community Impact</span><br/> Creating green job opportunities and fostering sustainable living positively impacts the lives of individuals and communities.</li>
                                    </ul>
                                </div>

                                <div className="col-12 col-md-6 mt-3 mt-md-0">
                                    <h3>Our Impacts</h3>
                                    <hr/>
                                    <ul className="list-unstyled">
                                        <li><span>Waste Reduction</span><br/> Partnering with urban households and businesses, we have diverted millions of tons of waste from landfills, reducing the environmental burden..</li>
                                        <li><span>Carbon Footprint Reduction</span><br/> By promoting recycling and proper waste disposal, we have significantly lowered carbon emissions, contributing to climate change mitigation.</li>
                                        <li><span>Community Engagement</span><br/> Our initiatives have empowered communities, creating awareness about waste management and environmental preservation.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section id="partner">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <h1>Partner with Us - Recyclers and Manufacturers</h1>
                            <h2>Join the Circular Revolution - Become a Recycling Champion!</h2>
                            <p>At Ebusaka, we believe in the power of partnerships to drive change. Join us as a recycler or manufacturer and play a crucial role in building a circular economy.</p>
                            <p className="__it">Are you passionate about recycling and transforming waste into valuable resources? Join our network of dedicated recyclers and be part of the solution? <Link to="/insider/partner">Learn More & Join Now</Link></p>
                        </div>

                        <div className="col-12 col-md-7 my-auto text-center">
                            <img className="img-fluid" src="/assets/resources/recycler.jpg" alt="recycler" />
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <button id="subscribe" className="d-none d-md-block">
                Subscribe
            </button>

            <section id="collaborator">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7 order-md-1 bg-white p-4 p-md-5">
                            <h1>Our Collaborators - Local Governments, Garbage Collectors</h1>
                            <h2>Powering Sustainable Communities - Our Collaborators in Action</h2>
                            <p>At Ebusaka, we are proud to collaborate with local governments, municipalities, and dedicated garbage collectors who share our vision of a cleaner, greener future.</p>
                            <p><span>Local Governments and Municipalities Partner</span><br/> with Ebusaka to implement efficient waste management solutions, achieve sustainability goals, and engage citizens in environmental initiatives. <Link to="/insider/partner">Learn More</Link></p>
                            <p><span>Garbage Collectors</span><br/>Join our network of professional garbage collectors and play a vital role in keeping communities clean and waste-free. The Ebusaka app connects you with residents, simplifies collection scheduling, and ensures transparency in operations. <Link to="/insider/partner">Learn More & Join</Link></p>
                        </div>

                        <div className="col-12 col-md-5 order-md-2 my-auto text-center">
                            <img src="/assets/resources/community-team.jpg" alt="community-team" />
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section id="support" className="bg-white text">
                <div className="container">
                    <h1 className="text-center">Supported By</h1>
                    <p className="text-center">Our valued partners who have graciously contributed and supported our mission.</p>
                    <div className="row text-center">
                        <div className="col-md-2 my-auto">
                            <a href="https://www.zicta.zm" target="_blank"><img src="/assets/resources/zicta-logo.png" className="img-fluid" alt="zicta-logo"/></a>
                        </div>

                        <div className="col-md-2 my-auto">
                            <a href="https://web.facebook.com/AGSProgZambia" target="_blank"><img src="/assets/resources/ags-logo.png" className="img-fluid" alt="ags-logo"/></a>
                        </div>

                        <div className="col-md-2">
                            <a href="https://www.undp.org/zambia" target="_blank"><img src="/assets/resources/undp-logo.png" className="img-fluid" alt="undp-logo"/></a>
                        </div>

                        <div className="col-md-2 my-auto">
                            <a href="https://ntbc.co.zm" target="_blank"><img src="/assets/resources/ntbc-logo.jpg" className="img-fluid" alt="ntbc-logo"/></a>
                        </div>

                        <div className="col-md-2 my-auto">
                            <a href="https://www.mtn.zm" target="_blank"><img src="/assets/resources/mtn-logo.jpg" className="img-fluid" alt="mtn-logo"/></a>
                        </div>

                        <div className="col-md-2 my-auto">
                            <a href="https://totalenergies.co.zm" target="_blank"><img src="/assets/resources/total-energies-logo.png" className="img-fluid" alt="total-energies-logo"/></a>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </>
    );
};

export default Home;
