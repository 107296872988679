import React from 'react';
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import {truncate} from "../../utils/trunate";

const MediaRow = (props) => {

    let {data, title, route} = props;

    return (
        <section id="media-row" style={props.style}>
            <div className="container">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <h1>{title}</h1>
                    <Link to={route}>See More <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M9.11612 1.11612C9.60427 0.627961 10.3957 0.627961 10.8839 1.11612L16.8839 7.11612C17.372 7.60427 17.372 8.39573 16.8839 8.88388L10.8839 14.8839C10.3957 15.372 9.60427 15.372 9.11612 14.8839C8.62796 14.3957 8.62796 13.6043 9.11612 13.1161L12.9822 9.25H2C1.30964 9.25 0.75 8.69036 0.75 8C0.75 7.30964 1.30964 6.75 2 6.75H12.9822L9.11612 2.88388C8.62796 2.39573 8.62796 1.60427 9.11612 1.11612Z" fill="#359636"/>
                    </svg>
                    </Link>
                </div>
                <div className="row">
                    {data.slice(0, 3).map((item) => {
                        return(
                            <div className="col-12 col-md-4 mb-5 mb-md-0">
                                <Link to={`/media-room/${item._id}`}>
                                    <div className="img-container">
                                        <img src={item.img_uri} alt="media images"/>
                                    </div>
                                    <h2>{truncate(item.title, 48)}</h2>
                                    <span>Aug 03, 2023</span>
                                </Link>
                            </div>
                        )
                    })}
                </div>
            </div>
        </section>
    );
};

MediaRow.propTypes = {
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired
};

export default MediaRow;
