import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import {Link, useLoaderData} from "react-router-dom";
import {truncate} from "../../utils/trunate";
import moment from "moment";
import SEO from "../../components/layout/Seo";

const List = () => {

    const {media, title} = useLoaderData();

    return (
        <>
            <Header/>
            <SEO
                title={`${title} - Media Room`}
                description={`${title} - Media Room`}
            />
            <section id="media-list">
                <div className="container">
                    <h1>{title}</h1>
                    <form>
                        <div className="form-row">
                            <div className="col-12 col-md-6">
                                <input type="text" className="form-control" id="inputName" placeholder="Search Article"/>
                            </div>
                            <div className="col-12 col-md-2 d-flex align-content-end flex-wrap mt-3 mt-md-0">
                                <button className="btn btn-primary btn-block">Search</button>
                            </div>
                        </div>
                    </form>

                    {media.map((item) => {
                        return(
                            <div className="media-item row" key={item._id}>
                                <div className="col-12 col-md-9 my-auto order-1 order-md-0">
                                    <Link to={`/media-room/${item._id}`} style={{textDecoration: "none"}}>
                                        <h2 className="mt-2 mt-md-0">{item.title}</h2>
                                    </Link>
                                    <div dangerouslySetInnerHTML={{__html: truncate(atob(item.description), 330)}}/>
                                    <span>{moment(item.createdAt).format("Do MMM, YYYY")}</span>
                                </div>

                                <div className="col-12 col-md-3 order-0 order-md-1">
                                    <Link to={`/media-room/${item._id}`} style={{textDecoration: "none"}}>
                                        <div className="__img-container">
                                            <img src={item.img_uri} className="img-fluid" alt={item.title} />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </section>
            <Footer/>
        </>
    );
};

export default List;
