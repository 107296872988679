import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import SideHeader from "../../components/layout/SideHeader";
import Accordion from "../../components/layout/Accordion";
import faq from "../../json/faq.json";
import AppDownload from "../../components/layout/AppDownload";

const BulkTrashPickUp = () => {
    return (
        <>
            <Header/>
            <SideHeader
                title="Bulk Trash Pickup"
                subtitle="Convenience with Care"
                description="Ebusaka's Bulk Trash Pickup/Special Pickup service is designed to make waste disposal effortless and environmentally responsible. Whether you have bulky items or require a one-time waste collection for a special event, we've got you covered!"
                img_src={"/assets/resources/bulk-trash.jpg"}
            />
            <section id="gl-content">
                <div className="container">
                    <div className="row" style={{marginBottom: 68}}>
                        <div className="col-12">
                            <h1>Convenient, On-Demand Service</h1>
                            <p>With our Bulk Trash Pickup/Special Pickup service, you can schedule a collection at your convenience. Simply use the Ebusaka app to request a pickup, and our reliable team will arrive at your doorstep on the scheduled date to handle your waste.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h1>Hassle-Free Disposal</h1>
                            <p>Got bulky items, old furniture, or large amounts of waste from a special event? Let us handle it for you. Our dedicated team will efficiently and responsibly dispose of the waste, ensuring that it is properly recycled or managed in compliance with environmental regulations.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="residential-waste">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-7">
                            <h1>Why Choose Ebusaka for Bulk Pickup?</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="card-block text-center">
                                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M36.9331 10.5801H7.06445L8.53845 3.20097C8.61928 2.8037 8.83505 2.44662 9.14916 2.19032C9.46327 1.93402 9.85638 1.79428 10.2618 1.7948H33.734C34.1394 1.79428 34.5325 1.93402 34.8466 2.19032C35.1607 2.44662 35.3765 2.8037 35.4573 3.20097L36.9331 10.5801Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M32.5423 10.5802H11.459L12.3372 5.30933H31.6642L32.5423 10.5802Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33.6111 40.6651C33.5583 41.0903 33.352 41.4815 33.0309 41.7651C32.7098 42.0488 32.2961 42.2052 31.8676 42.2051H11.3013C10.8624 42.207 10.4387 42.0445 10.1137 41.7497C9.78861 41.4548 9.58572 41.0489 9.54495 40.6119L7.06445 14.0946H36.9331L33.6111 40.6651Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.4496 12.3365C40.4496 12.8023 40.2646 13.2491 39.9352 13.5785C39.6058 13.9078 39.1591 14.0929 38.6933 14.0929H5.31194C4.84589 14.0929 4.39892 13.9077 4.06937 13.5782C3.73983 13.2486 3.55469 12.8017 3.55469 12.3356C3.55469 11.8696 3.73983 11.4226 4.06937 11.0931C4.39892 10.7635 4.84589 10.5784 5.31194 10.5784H38.6933C39.1591 10.5784 39.6058 10.7634 39.9352 11.0928C40.2646 11.4222 40.4496 11.8707 40.4496 12.3365Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M33.6111 40.6651C33.5583 41.0903 33.352 41.4815 33.0309 41.7651C32.7098 42.0488 32.2961 42.2052 31.8676 42.2051H11.3013C10.8624 42.207 10.4387 42.0445 10.1137 41.7497C9.78861 41.4548 9.58572 41.0489 9.54495 40.6119L7.06445 14.0946H36.9331L33.6111 40.6651Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.0454 29.282L16.7896 26.6035L13.6289 27.2818" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.3092 33.3886H15.2952C15.0453 33.3901 14.7994 33.3254 14.5826 33.2012C14.3658 33.0769 14.1857 32.8974 14.0608 32.681C13.9358 32.4646 13.8704 32.219 13.8711 31.9691C13.8718 31.7191 13.9387 31.4739 14.065 31.2582L16.7783 26.6052" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.8439 31.2107L22.9336 33.5794L24.8439 36.2194" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M27.7026 28.2662L29.5561 31.4746C29.6808 31.6902 29.7465 31.935 29.7465 32.1841C29.7465 32.4333 29.6808 32.678 29.556 32.8937C29.4313 33.1093 29.2519 33.2883 29.0359 33.4125C28.8199 33.5367 28.575 33.6018 28.3259 33.6012L22.9414 33.5792" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M22.7344 24.4584L25.9005 24.7683L27.2572 22.1558" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.0664 23.0322L20.7696 20.086C20.8932 19.8688 21.0721 19.6881 21.2882 19.5625C21.5043 19.4368 21.7498 19.3706 21.9997 19.3706C22.2497 19.3706 22.4952 19.4368 22.7113 19.5625C22.9274 19.6881 23.1063 19.8688 23.2299 20.086L25.9011 24.761" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Sustainable Waste</h2>
                                <p>Ebusaka is committed to promoting sustainability and a circular economy. We ensure that collected waste is directed towards recycling, reuse, or proper disposal to minimize environmental impact.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="card-block text-center">
                                <svg width="46" height="48" viewBox="0 0 46 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M22.082 1C22.5902 1 23.0775 1.2107 23.4368 1.58574C23.7961 1.96079 23.998 2.46945 23.998 2.99985V15.0031C23.998 15.2657 23.9484 15.5258 23.8522 15.7684C23.7559 16.011 23.6147 16.2315 23.4368 16.4172C23.2589 16.6029 23.0477 16.7502 22.8152 16.8507C22.5828 16.9512 22.3336 17.003 22.082 17.003H18.248V22.0067L13.456 17.005H2.916C2.66439 17.005 2.41524 16.9533 2.18278 16.8528C1.95032 16.7523 1.7391 16.605 1.56118 16.4193C1.38327 16.2336 1.24213 16.0131 1.14585 15.7705C1.04956 15.5279 1 15.2678 1 15.0052V3.00193C1 2.47154 1.20186 1.96287 1.56118 1.58783C1.9205 1.21279 2.40785 1.00209 2.916 1.00209L22.082 1Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M44.7542 21.0068C43.9129 19.4865 42.7031 18.2242 41.2464 17.3465C39.7896 16.4689 38.1373 16.0068 36.4552 16.0068C34.7732 16.0068 33.1209 16.4689 31.6641 17.3465C30.2074 18.2242 28.9976 19.4865 28.1562 21.0068" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M31.4922 4.00183C32.389 4.95651 33.4601 5.71385 34.6422 6.22909C35.8242 6.74433 37.0932 7.00701 38.3742 7.0016C39.6926 7.00631 40.9979 6.72702 42.2082 6.18121" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30.7031 7.00163C30.7031 8.59336 31.3089 10.1199 32.3873 11.2454C33.4656 12.3709 34.9281 13.0033 36.4531 13.0033C37.9781 13.0033 39.4407 12.3709 40.519 11.2454C41.5973 10.1199 42.2031 8.59336 42.2031 7.00163C42.2031 5.4099 41.5973 3.88336 40.519 2.75784C39.4407 1.63231 37.9781 1 36.4531 1C34.9281 1 33.4656 1.63231 32.3873 2.75784C31.3089 3.88336 30.7031 5.4099 30.7031 7.00163Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M17.9222 47.0132C17.0809 45.493 15.8711 44.2306 14.4144 43.353C12.9576 42.4753 11.3052 42.0133 9.62322 42.0133C7.94121 42.0133 6.28882 42.4753 4.83207 43.353C3.37532 44.2306 2.16552 45.493 1.32422 47.0132" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.66016 30.0082C6.02115 31.4666 7.77608 32.4568 9.69053 32.8466C11.605 33.2364 13.5878 33.0072 15.3742 32.1896" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M3.875 33.0101C3.875 34.6018 4.4808 36.1283 5.55914 37.2538C6.63747 38.3794 8.1 39.0117 9.625 39.0117C11.15 39.0117 12.6125 38.3794 13.6909 37.2538C14.7692 36.1283 15.375 34.6018 15.375 33.0101C15.375 31.4183 14.7692 29.8918 13.6909 28.7663C12.6125 27.6407 11.15 27.0084 9.625 27.0084C8.1 27.0084 6.63747 27.6407 5.55914 28.7663C4.4808 29.8918 3.875 31.4183 3.875 33.0101Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.2041 9.00354H6.74609" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M10.5801 6.00171L6.74609 9.00357L10.5801 12.0054" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M23.998 25.0065C23.4899 25.0065 23.0025 25.2172 22.6432 25.5922C22.2839 25.9673 22.082 26.4759 22.082 27.0063V39.0096C22.082 39.54 22.2839 40.0486 22.6432 40.4237C23.0025 40.7987 23.4899 41.0094 23.998 41.0094H27.832V46.0132L32.624 41.0115H43.166C43.6742 41.0115 44.1615 40.8008 44.5208 40.4258C44.8802 40.0507 45.082 39.5421 45.082 39.0117V27.0084C45.082 26.478 44.8802 25.9693 44.5208 25.5943C44.1615 25.2193 43.6742 25.0086 43.166 25.0086L23.998 25.0065Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M26.875 33.01H39.333" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M35.4961 30.0082L39.3301 33.01L35.4961 36.0119" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Efficient & Reliable Service</h2>
                                <p>Planning a party or event? Don't stress about the post-celebration cleanup. Our once-off special pickup option allows you to request waste collection when you need it most.</p>
                            </div>
                        </div>

                        <div className="col-md-4 mt-3 mt-md-0">
                            <div className="card-block text-center">
                                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M30.707 39.0439V46.0419" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M30.707 39.0439L32.767 38.3579C33.5968 38.0592 34.4853 37.96 35.3605 38.0684C36.2358 38.1768 37.0732 38.4897 37.805 38.9819C38.7334 39.51 39.7575 39.8482 40.8179 39.9766C41.8782 40.105 42.9534 40.0212 43.981 39.7299L46.041 39.0439V29.1939C46.0413 29.0419 46.0053 28.892 45.9362 28.7566C45.867 28.6212 45.7667 28.5042 45.6433 28.4153C45.52 28.3264 45.3773 28.2681 45.2271 28.2453C45.0768 28.2224 44.9232 28.2356 44.779 28.2839L43.979 28.5499C41.8809 29.1106 39.6468 28.8315 37.751 27.7719C37.0155 27.3114 36.1872 27.0193 35.3256 26.9166C34.4639 26.8139 33.5902 26.9031 32.767 27.1779L30.707 27.8639V39.0439Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M1.95703 30.708C1.95703 27.658 3.16863 24.7329 5.3253 22.5763C7.48197 20.4196 10.407 19.208 13.457 19.208C16.507 19.208 19.4321 20.4196 21.5888 22.5763C23.7454 24.7329 24.957 27.658 24.957 30.708H1.95703Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M6.26953 9.14601C6.26953 10.0899 6.45545 11.0246 6.81669 11.8967C7.17792 12.7688 7.70738 13.5612 8.37485 14.2287C9.04232 14.8962 9.83471 15.4256 10.7068 15.7869C11.5789 16.1481 12.5136 16.334 13.4575 16.334C14.4015 16.334 15.3362 16.1481 16.2083 15.7869C17.0803 15.4256 17.8727 14.8962 18.5402 14.2287C19.2077 13.5612 19.7371 12.7688 20.0984 11.8967C20.4596 11.0246 20.6455 10.0899 20.6455 9.14601C20.6455 7.23963 19.8882 5.41134 18.5402 4.06332C17.1922 2.71531 15.3639 1.95801 13.4575 1.95801C11.5512 1.95801 9.72286 2.71531 8.37485 4.06332C7.02684 5.41134 6.26953 7.23963 6.26953 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M24.4805 9.14601C24.4805 11.0524 25.2378 12.8807 26.5858 14.2287C27.9338 15.5767 29.7621 16.334 31.6685 16.334C33.5748 16.334 35.4031 15.5767 36.7512 14.2287C38.0992 12.8807 38.8565 11.0524 38.8565 9.14601C38.8565 7.23963 38.0992 5.41134 36.7512 4.06332C35.4031 2.71531 33.5748 1.95801 31.6685 1.95801C29.7621 1.95801 27.9338 2.71531 26.5858 4.06332C25.2378 5.41134 24.4805 7.23963 24.4805 9.14601Z" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M40.2441 23.0421C38.4837 21.0702 36.0977 19.7649 33.4877 19.346C30.8777 18.9271 28.2031 19.4201 25.9141 20.7421" stroke="#359636" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <h2>Community-Focused</h2>
                                <p>By choosing Ebusaka, you contribute to local job creation and support community development initiatives. We take pride in empowering communities through our waste management services.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <section id="gl-content" style={{background: "white", paddingBottom: 30, paddingTop: 30}}>
                <div className="container">
                    <div className="row" style={{marginBottom: 68}}>
                        <div className="col-12">
                            <h1>Giving New Life to Your Waste</h1>
                            <p>At Ebusaka, we believe that waste is not just waste; it can be a valuable resource. Through our Bulk Trash Pickup/Special Pickup service, we ensure that your waste is given a new life – either through recycling or other environmentally friendly processes</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h1>Together, Let's Make a Difference</h1>
                            <p>By choosing Ebusaka, you become a part of the solution in keeping our communities and the environment clean. Our Bulk Trash Pickup/Special Pickup service is an extension of our commitment to creating a positive impact on society and the planet.</p>
                        </div>
                    </div>
                </div>
            </section>

            <hr className="d-block d-sm-none"/>

            <Accordion
                title="Frequently Asked Questions (FAQs)"
                data={faq.support}
            />

            <AppDownload
                img_src="/assets/resources/app-download.png"
                title="Book Your Pickup Today"
                description={
                    [
                        "Make waste disposal a breeze with Ebusaka's Bulk Trash Pickup/Special Pickup service. Download our app and schedule your pickup with ease. Take a step towards responsible waste management and join us in building a cleaner, greener future for Zambia."
                    ]
                }
            />

            <Footer/>
        </>
    );
};

export default BulkTrashPickUp;
