import React from 'react';
import Header from "../../components/layout/Header";
import Footer from "../../components/layout/Footer";
import Assistance from "../../components/layout/Assistance";
import {Link} from "react-router-dom";
import teams from "../../json/teams.json";
import $ from "jquery";
import {truncate} from "../../utils/trunate";
import SEO from "../../components/layout/Seo";

const title = "Our Leadership Team";
const description = "Meet the brilliant minds behind Ebusaka - a diverse and passionate team committed to revolutionizing waste management and driving positive change in Africa and beyond."
const Team = () => {

    const displayPort = (data) => {
        $("#name").text(data.name);
        $("#position").text(data.position);
        $("#description").text(data.description);
        $("#img_source").attr("src", data.img_url);
        $("#modalTeam").modal({ backdrop: 'static', focus: false, show: true });
    }

    return (
        <>
            <SEO
                title={title}
                description={description}
            />
            <Header/>
            <section id="team-header">
                <div className="container">
                    <div id="global-header">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <h1>{title}</h1>
                                <p>{description}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="team-list">
                <div className="container">
                    <div className="row">
                        {teams.map((item, index) => {
                            return(
                                <div key={item._id} id="team-list-block" className="col-12 col-md-4" style={index > 2 ? {marginTop: 160} : null}>
                                    <div className="team-profile">
                                        <img src={item.img_url} alt="team-1" />
                                        <h2>{item.name}</h2>
                                        <h3>{item.position}</h3>
                                        <p>{truncate(item.description, 210)}</p>
                                        <Link to="#" onClick={displayPort.bind(null, item)}>Read More</Link>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>

            <Assistance
                title="Interested in joining Ebusaka?"
                description="At Ebusaka, we believe that our success lies in the talent and dedication of our team members."
                tag="Visit Career Opportunity"
                route="/insider/career"
            />
            <Footer/>

            <div className="modal" id="modalTeam" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header pd-y-20 pd-x-20 pd-sm-x-30">
                            <a href="#" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal" aria-label="Close">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                </svg>
                            </a>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 d-md-flex" id="team-title">
                                    <img id="img_source" src="assets/resources/team-1.jpg" className="img-fluid" alt="team-1" />
                                    <div className="my-auto ml-md-4">
                                        <h2 id="name">Muyunda Kaonga</h2>
                                        <h3 id="position">Founder & Chief Excecutive Officer</h3>
                                    </div>
                                </div>

                                <div className="col-12 mt-4">
                                    <p id="description">Muyunda Kaonga - Founder and CEO: As the visionary leader of Ebusaka, Muyunda Kaonga is the driving force behind our mission to create a world where waste is managed responsibly and sustainably. With a Bachelor's degree in Computer Applications and specialized training from the Accelerated Growth for SMEs in Zambia (AGS) Programme, Muyunda is an award-winning innovator with a remarkable track record. His achievements include being a Top 6 Zambian Innovators at Slush, Helsinki Finland 2022, the winner of the 2022 MTN Momo Hackathon, and being among the UNDP's Top 10 Innovators on Sustainable Waste Management and Recycling. Muyunda's expertise in project management, business development, and entrepreneurship has led Ebusaka to the forefront of the green tech startup ecosystem, with a strong focus on using disruptive technology to address environmental challenges.</p>
                                    <div className="mt-4">
                                        <a href="#" className="d-flex justify-content-center" role="button" data-dismiss="modal" aria-label="Close">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z" fill="#22282F"/>
                                            </svg> Close
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Team;
