import React from 'react';
import {Link} from "react-router-dom";

const Header = () => {
    return (
        <header>
            <div id="nav-header">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <span>US - English</span>
                        <div className="d-flex">
                            <ul className="list-unstyled">
                                <li className="list-inline-item"><Link to="/drop-off-location">Drop Off Location</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <Link className="navbar-brand" to="/">
                        <img src="/assets/resources/logo.svg" alt="logo"/>
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto mb-2 mb-lg-0">
                            <li className="nav-item dropdown position-static">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownInside" role="button" data-toggle="dropdown" aria-expanded="false">
                                    For Home
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownInside">
                                    <div className="container">
                                        <div className="row w-100">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <span>Residential Services</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/home/residential-waste-pickup">Residential Waste & Recycling Pickup</Link></li>
                                                    <li><Link className="list-inline-item" to="/home/bulk-trash-pickup">Bulk Trash Pickup</Link></li>
                                                    <li><Link className="list-inline-item" to="/home/trade-waste">Trade Waste</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-md-0">
                                                <span>Community Services</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/support">Report Services</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-none d-md-block">
                                                <div className="d-flex justify-content-center" id="_app-download">
                                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ebusaka" className="ml-4 mr-2" rel="noreferrer"><img src="/assets/resources/icons/playstore.svg" height="40" alt="playstore" /></a>
                                                    <a target="_blank" href="https://apps.apple.com/us/app/ebusaka/id6443470100" rel="noreferrer"><img src="/assets/resources/icons/appstore.svg" height="40" alt="appstore" /></a>
                                                </div>
                                                <img src="/assets/resources/app-screenshot.png" height="255" alt="ebusaka-app-screenshot"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown position-static">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownInside" role="button" data-toggle="dropdown" aria-expanded="false">
                                    For Business
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownBusiness">
                                    <div className="container">
                                        <div className="row w-100">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <span>Business Services</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/business/business-waste-pickup">Business Waste & Recycling Pickup</Link></li>
                                                    <li><Link className="list-inline-item" to="/business/plastic-credit">Plastic Credit</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-md-0">
                                                <span>Solutions By Industry</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/business/recycler">Recyclers</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-none d-md-block">
                                                <img src="/assets/resources/bins.jpg" className="img-fluid" alt="ebusaka-app-screenshot"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item dropdown position-static">
                                <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdownInside" role="button" data-toggle="dropdown" aria-expanded="false">
                                    Inside Ebusaka
                                </Link>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdownInside">
                                    <div className="container">
                                        <div className="row w-100">
                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4">
                                                <span>About Ebusaka</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/insider/who-we-are">Who We Are</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/story">Our Story</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/team">Our Teams</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/partner">Partners</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/career">Careers</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/award">Achievement, Awards & Recognition</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 mt-4 mt-md-0">
                                                <span>Sustainability</span>
                                                <ul className="list-unstyled">
                                                    <li><Link className="list-inline-item" to="/insider/social-impact">Social Impact</Link></li>
                                                    <li><Link className="list-inline-item" to="/insider/economic-impact">Economic Impact</Link></li>
                                                </ul>
                                            </div>

                                            <div className="col-12 col-sm-12 col-md-4 col-lg-4 d-none d-md-block">
                                                <div className="row text-center">
                                                    <img src="/assets/resources/waste.jpg" className="img-fluid" alt="ebusaka-app-screenshot"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/media-room">Media Room</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link" to="/support">Support</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
