import React from 'react';
import PropTypes from "prop-types";

const AppDownload = (props) => {

    let {title, description, img_src} = props;

    return (
        <section id="app-download">
             <div className="container">
                 <div className="row">
                     <div className="col-12 col-md-7 my-auto text-center text-md-left">
                         <h1>{title}</h1>
                         {description.map((item) => {
                             return(
                                 <p>{item}</p>
                             )
                         })}
                         <div className="d-flex justify-content-center justify-content-md-start mb-3 mb-md-0" style={{marginTop: 44}}>
                             <a target="_blank" href="https://play.google.com/store/apps/details?id=com.ebusaka" rel="noreferrer"><img src="/assets/resources/icons/playstore.svg" className="mr-1" height="46" alt="playstore" /></a>
                             <a target="_blank" href="https://apps.apple.com/us/app/ebusaka/id6443470100" rel="noreferrer"><img src="/assets/resources/icons/appstore.svg" className="ml-1" height="46" alt="playstore" /></a>
                         </div>
                     </div>

                     <div className="col-12 col-md-5">
                        <img src={img_src} className="img-fluid" alt="app-download" />
                     </div>
                 </div>
             </div>
        </section>
    );
};

AppDownload.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.array.isRequired,
    optional_description: PropTypes.string,
    img_src: PropTypes.string.isRequired
};

export default AppDownload;
